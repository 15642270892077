const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      yAxisID: 'fdsa',
      label: 'Number of Listing Requests',
      backgroundColor: 'rgba(0, 110, 144, 1)',
      borderColor: 'rgba(0, 110, 144, 1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(0, 110, 144, 0.5)',
      hoverBorderColor: 'rgba(0, 110, 144, 0.8)',
      data: [65, 59, 80, 81, 56, 55, 40]
    },
    {
      yAxisID: 'asdf',
      label: 'Dollar value of Listing Requests',
      backgroundColor: 'rgba(153, 194, 77,1)',
      borderColor: 'rgba(153, 194, 77,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(153, 194, 77,0.5)',
      hoverBorderColor: 'rgba(153, 194, 77,0.8)',
      data: [5000, 9000, 8100, 600, 5500, 4000, 6000]
    }
  ]
};

export default data
