import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout/Layout';
import listingRequestData from '../../data/listingRequestData';
import listingRationale from '../../data/listingRationale';
import listingsSold from '../../data/listingsSold';
import ChartNumberAndDollar from '../../components/ChartNumberAndDollar';
import ChartRationale from '../../components/ChartRationale';
import ChartDollar from '../../components/ChartDollar';

export default () => (
  <Layout>
    <section id="services" className="py-50 lg:pb-10 lg:pt-12">
      <div className="container mx-auto text-center">
        <Link to="/demo">Back to demo</Link>
        <h2 className="text-3xl lg:text-5xl font-semibold">Listing Request Totals</h2>
        <div className="flex py-20">
          <ChartNumberAndDollar data={listingRequestData} />
        </div>
      </div>

      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Listing Request Rationale</h2>
        <div className="flex py-20">
          <ChartRationale data={listingRationale} />
        </div>
      </div>

      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Listings Sold</h2>
        <div className="flex py-20">
          <ChartDollar data={listingsSold} />
        </div>
      </div>
    </section>
  </Layout>
);


