const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'No Procedure Needs This',
      backgroundColor: 'rgba(244, 224, 77,1)',
      borderColor: 'rgba(244, 224, 77,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(244, 224, 77,0.5)',
      hoverBorderColor: 'rgba(244, 224, 77,0.8)',
      data: [35000, 43000, 59000, 49000, 8100, 5600, 55000, 42000]
    },
    {
      label: 'Extra Stock is Unused',
      backgroundColor: 'rgba(242, 237, 111,1)',
      borderColor: 'rgba(242, 237, 111,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(242, 237, 111,0.5)',
      hoverBorderColor: 'rgba(242, 237, 111,0.8)',
      data: [22000, 32000, 45000, 13000, 56000, 32000, 40000, 6500]
    },
    {
      label: 'Equipment was Replaced By Newer Version',
      backgroundColor: 'rgba(206, 227, 151,1)',
      borderColor: 'rgba(206, 227, 151,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(206, 227, 151,0.5)',
      hoverBorderColor: 'rgba(206, 227, 151,0.8)',
      data: [32000, 28000, 8100, 56000, 55000, 40000, 6500, 11000]
    }
  ]
};

export default data