const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      yAxisID: 'asdf',
      label: 'Listings Requested',
      backgroundColor: 'rgba(255, 210, 63, 1)',
      borderColor: 'rgba(255, 210, 63, 1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255, 210, 63, 0.5)',
      hoverBorderColor: 'rgba(255, 210, 63, 0.8)',
      data: [6700, 12000, 9800, 9200, 15500, 12000, 8300]
    },
    {
      yAxisID: 'asdf',
      label: 'Listings',
      backgroundColor: 'rgba(59, 206, 172,1)',
      borderColor: 'rgba(59, 206, 172,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(59, 206, 172,0.5)',
      hoverBorderColor: 'rgba(59, 206, 172,0.8)',
      data: [5000, 9000, 8100, 8100, 5500, 4000, 6000]
    },
    {
      yAxisID: 'asdf',
      label: 'Listings Successfully Sold',
      backgroundColor: 'rgba(14, 173, 105,1)',
      borderColor: 'rgba(14, 173, 105,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(14, 173, 105,0.5)',
      hoverBorderColor: 'rgba(14, 173, 105,0.8)',
      data: [4500, 8500, 7000, 1200, 4500, 3500, 3000]
    }
  ]
};

export default data
